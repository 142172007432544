Vue.component("cfour-storespecials-items", {

    delimiters: ["${", "}"],

    props: {
        template: {
            default: "#vue-cfour-productwall-items",
            type: String
        },
        cols: {
            default: 3
        },
        rows: {
            default: 4
        },
        special: {
            default: 0
        },
        sorting: {
            default: "variation.position_desc"
        }

    },

    data()
{
        return {
            items: [],
            gridClass: 12
        };
    },

    created()
{
        this.$options.template = this.template;
        $.ajax({
            url: "/CfourProductWall/storespecial/" + this.special,
            method: "post",
            data: "cols=" + this.cols + "&rows=" + this.rows + "&sorting=" + this.sorting
        }).done(data =>
{
            this.gridClass = 12 / this.cols;
            if (typeof data === "string")
{
                this.items = JSON.parse(data);
            }
            else
{
                this.items = data;
            }
            $(window).trigger("resize");
        });
    }
});
